<template>
    <div class="main-box">
        <div class="pd-box">
            <div class="title">
                <p>序号：{{xuhao}}</p>
            </div>
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                <div class="content">
                    <div class="con-item">
                        <a-form-item label="发票种类">
                            <a-input v-model:value="fapiaozhonglei" placeholder="请输入发票种类"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="发票代码">
                            <a-input v-model:value="fapiaodaima" placeholder="请输入发票代码"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="发票号码">
                            <a-input v-model:value="fapiaohaoma" placeholder="请输入发票号码"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="发票机号">
                            <a-input v-model:value="fapiaojihao" placeholder="请输入发票机号"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="购方名称">
                            <a-input v-model:value="goufangmingcheng" placeholder="请输入购方名称"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="购方税号">
                            <a-input v-model:value="goufangshuihao" placeholder="请输入购方税号"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="购方地址电话">
                            <a-input v-model:value="goufangdizhidianhua" placeholder="请输入购方地址电话"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="购方银行账号">
                            <a-input v-model:value="goufangyinhangzhanghao" placeholder="请输入购方银行账号"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="开票日期">
                            <a-input v-model:value="kaipiaoriqi" placeholder="请输入开票日期"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="报送状态">
                            <a-input v-model:value="baosongzhuangtai" placeholder="请输入报送状态"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="报送日志">
                            <a-input v-model:value="baosongrizhi" placeholder="请输入报送日志"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="所属月份">
                            <a-input v-model:value="suoshuyuefen" placeholder="请输入所属月份"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="合计金额">
                            <a-input v-model:value="hejijine" placeholder="请输入合计金额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="税率">
                            <a-input v-model:value="shuilv" placeholder="请输入税率"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="合计税额">
                            <a-input v-model:value="hejishuie" placeholder="请输入合计税额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="主要商品名称">
                            <a-input v-model:value="zhuyaoshangpinmingcheng" placeholder="请输入主要商品名称"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="商品税目">
                            <a-input v-model:value="shangpinshuimu" placeholder="请输入商品税目"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="开票人">
                            <a-input v-model:value="kaipiaoren" placeholder="请输入开票人"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="收款人">
                            <a-input v-model:value="shoukuanren" placeholder="请输入收款人"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="打印标志">
                            <a-input v-model:value="dayinbiaozhi" placeholder="请输入打印标志"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="作废标志">
                            <a-input v-model:value="zuofeibiaozhi" placeholder="请输入作废标志"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="清单标志">
                            <a-input v-model:value="qingdanbiaozhi" placeholder="请输入清单标志"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="修复标志">
                            <a-input v-model:value="xiufubiaozhi" placeholder="请输入修复标志"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="复合人">
                            <a-input v-model:value="fuheren" placeholder="请输入复合人"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="作废日期">
                            <a-input v-model:value="zuofeiriqi" placeholder="请输入作废日期"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="备注">
                            <a-input v-model:value="beizhu" placeholder="请输入备注"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </a-form>
        </div>
        <div class="con-box" style="text-align: center;">
            <a-button type="primary" style="margin-right: 20px;" @click="onSave">保存</a-button>
            <a-button type="primary" @click="$router.back()">取消</a-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'loginType',
            'isAuth',
            'orgId',
            'y'
        ])
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            id: '',
            fapiaozhonglei: '',
            fapiaodaima: '',
            fapiaohaoma: '',
            fapiaojihao: '',
            goufangmingcheng: '',
            goufangshuihao: '',
            goufangdizhidianhua: '',
            goufangyinhangzhanghao: '',
            kaipiaoriqi: '',
            baosongzhuangtai: '',
            baosongrizhi: '',
            suoshuyuefen: '',
            hejijine: '',
            shuilv: '',
            hejishuie: '',
            zhuyaoshangpinmingcheng: '',
            shangpinshuimu: '',
            kaipiaoren: '',
            shoukuanren: '',
            dayinbiaozhi: '',
            zuofeibiaozhi: '',
            qingdanbiaozhi: '',
            xiufubiaozhi: '',
            fuheren: '',
            zuofeiriqi: '',
            beizhu: '',
            xuhao: ''
        }
    },
    methods: {
        // 获取利润表
        getXXFPDetail () {
            this.$store.dispatch('getXXFPDetail', { JYDATA_XIAOXIANG_ID: this.id, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    let datas = res.pd
                    this.fapiaozhonglei = datas.FAPIAO_KIND
                    this.fapiaodaima = datas.FAPIAO_CODE
                    this.fapiaohaoma = datas.FAPIAO_NUMBER
                    this.fapiaojihao = datas.FAPIAO_MACHINE
                    this.goufangmingcheng = datas.GOUAGENT
                    this.goufangshuihao = datas.GOUSHUIHAO
                    this.goufangdizhidianhua = datas.GOUADDRPHONE
                    this.goufangyinhangzhanghao = datas.GOU_BANKCODE
                    this.kaipiaoriqi = datas.KAIPIAO_DATE
                    this.baosongzhuangtai = datas.BAOSONG_STATUS
                    this.baosongrizhi = datas.BAOSONG_LOG
                    this.suoshuyuefen = datas.SUOSHUMONTH
                    this.hejijine = datas.HEJIMONEY
                    this.shuilv = datas.SHUILV
                    this.hejishuie = datas.TOTAL_SHUIE
                    this.zhuyaoshangpinmingcheng = datas.PRIMARYAGENT
                    this.shangpinshuimu = datas.SPSM
                    this.kaipiaoren = datas.KAIPIAOREN
                    this.shoukuanren = datas.SHOUKUANREN
                    this.dayinbiaozhi = datas.DAYINBIAOZHI
                    this.zuofeibiaozhi = datas.ZUOFEIBIAOZHI
                    this.qingdanbiaozhi = datas.QINGDANBIAOZHI
                    this.xiufubiaozhi = datas.XIUFUBIAOZHI
                    this.fuheren = datas.FUHEREN
                    this.zuofeiriqi = datas.ZUOFEIDATE
                    this.beizhu = datas.BEIZHU
                } else {
                    this.$message.error('获取销项发票详情失败，请稍后重试！')
                }
            })
        },
        onSave () {
            this.$store.dispatch('editXXFPItem', {
                YEAR: this.y,
                JYDATA_XIAOXIANG_ID: this.id,
                ENTERPRISE_ID: this.orgId,
                FAPIAO_KIND: this.fapiaozhonglei,
                FAPIAO_CODE: this.fapiaodaima,
                FAPIAO_NUMBER: this.fapiaohaoma,
                FAPIAO_MACHINE: this.fapiaojihao,
                GOUAGENT: this.goufangmingcheng,
                GOUSHUIHAO: this.goufangshuihao,
                GOUADDRPHONE: this.goufangdizhidianhua,
                GOU_BANKCODE: this.goufangyinhangzhanghao,
                KAIPIAO_DATE: this.kaipiaoriqi,
                BAOSONG_STATUS: this.baosongzhuangtai,
                BAOSONG_LOG: this.baosongrizhi,
                SUOSHUMONTH: this.suoshuyuefen,
                HEJIMONEY: this.hejijine,
                SHUILV: this.shuilv,
                TOTAL_SHUIE: this.hejishuie,
                PRIMARYAGENT: this.zhuyaoshangpinmingcheng,
                SPSM: this.shangpinshuimu,
                BEIZHU: this.beizhu,
                KAIPIAOREN: this.kaipiaoren,
                SHOUKUANREN: this.shoukuanren,
                DAYINBIAOZHI: this.dayinbiaozhi,
                ZUOFEIBIAOZHI: this.zuofeibiaozhi,
                QINGDANBIAOZHI: this.qingdanbiaozhi,
                XIUFUBIAOZHI: this.xiufubiaozhi,
                FUHEREN: this.fuheren,
                ZUOFEIDATE: this.zuofeiriqi,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('修改成功！')
                    setTimeout(() => {
                        this.$router.back()
                    }, 1500)
                } else {
                    this.$message.error('保存失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.id = this.$route.query.id
        this.xuhao = this.$route.query.num
        this.getXXFPDetail()
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
        }
    }
}
</style>